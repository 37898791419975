<template>
  <div>
    <div class="tabs">
      <div :class="['tab', tabIndex==0 ? 'active':'']" @click="tabIndex = 0">更改绑定手机号</div>
      <div :class="['tab', tabIndex==1 ? 'active':'']" @click="tabIndex = 1">密码设置</div>
    </div>
    <div class="panel" v-if="tabIndex == 0">
      <div class="flex items-center" v-if="userData">
        <div>已绑定手机号：{{userData.phone || ''}}</div>
        <div class="btn-change btn-fill-theme" @click="exchangePhone">更改</div>
      </div>
    </div>
    <div class="panel" v-if="tabIndex == 1 && !isEditPwd">
      <div class="flex items-center" v-if="userData">
        <div>登录密码：********</div>
        <div class="btn-change btn-fill-theme" @click="isEditPwd = true">修改密码</div>
      </div>
    </div>
    <div class="panel" v-if="tabIndex == 1 && isEditPwd">
      <div class="tabs border-bottom">
        <div :class="['tab', isModifybyPwd ? 'active':'']" @click="isModifybyPwd = true">原密码修改</div>
        <div :class="['tab', !isModifybyPwd ? 'active':'']" @click="isModifybyPwd = false">验证码修改</div>
      </div>
      <div class="setting-wrap">
        <template v-if="isModifybyPwd">
          <!-- <div class="label required">原密码：</div>
          <el-input :type="form.oldPwdShow ? 'text': 'password'" v-model="userData.pwdOrSms" placeholder="请输入原密码">
            <div slot="suffix" class="icon-eyes" @click="form.oldPwdShow = !form.oldPwdShow">
              <img v-if="form.oldPwdShow" src="@/assets/images/pwd-show.png">
              <img v-else src="@/assets/images/pwd-hide.png">
            </div>
          </el-input> -->
        </template>
        <template v-else>
          <div class="label">手机号：</div>
          <el-input type="text" :value="userData.phone" disabled></el-input>
          <div class="label required mt24">验证码：</div>
          <div class="flex">
            <div class="flex-1">
              <el-input type="number" placeholder="请输入验证码" v-model="form.pwdOrSms"></el-input>
            </div>
            <div :class="['btn-sms', isSendSms ? 'btn-border':'btn-fill-theme']" @click="sendSms">
              {{isSendSms ? `剩余${countdown}` : '获取验证码'}}
            </div>
          </div>
        </template>
        <div class="label required mt24">新密码：</div>
        <el-input :type="form.newPwdShow ? 'text':'password'" placeholder="请输入新密码" v-model="form.newPwd">
          <div slot="suffix" class="icon-eyes" @click="form.newPwdShow = !form.newPwdShow">
            <img v-if="form.newPwdShow" src="@/assets/images/pwd-show.png">
            <img v-else src="@/assets/images/pwd-hide.png">
          </div>
        </el-input>
        <div class="label required mt24">确认新密码：</div>
        <el-input :type="form.newPwdConfirmShow ? 'text':'password'" placeholder="请确认新密码" v-model="form.newPwdConfirm">
          <div slot="suffix" class="icon-eyes" @click="form.newPwdConfirmShow = !form.newPwdConfirmShow">
            <img v-if="form.newPwdConfirmShow" src="@/assets/images/pwd-show.png">
            <img v-else src="@/assets/images/pwd-hide.png">
          </div>
        </el-input>
        <!-- 提交/取消 -->
        <div class="btn-group">
          <div class="btn-cancel btn-border" @click="isEditPwd = false">取消</div>
          <div class="btn-confirm btn-fill-theme" @click="handleSettingPwd()">确认</div>
        </div>
      </div>
    </div>
    <!-- 验证旧手机号弹窗 -->
    <verifyPhonePopup ref="verifyPhoneRef" @verify="handleVerify"></verifyPhonePopup>
    <!-- 绑定新手机号弹窗 -->
    <modifyPhonePopup ref="modifyPhoneRef"></modifyPhonePopup>
  </div>
</template>

<script>
import verifyPhonePopup from './components/verifyPhonePopup.vue'
import modifyPhonePopup from './components/modifyPhonePopup.vue'
import { userProfileApi, changePwdApi, setPwdApi } from '@/api/user.js'
import { pwdEncrypt } from '@/utils/util.js'
export default {
  name: "UserAccountSafty",
  components: {
    verifyPhonePopup,
    modifyPhonePopup
  },
  data() {
    return {
      userData: null,
      tabIndex: 0,
      isModifybyPwd: true, // 控制原密码修改/验证码修改
      countdown: 60,
      isSendSms: false,
      isEditPwd: false, // 控制修改密码面板显隐
      form: {
        oldPwdOrSms: '',
        newPwd: '',
        newPwdConfirm: '',
        oldPwdShow: false,
        newPwdShow: false,
        newPwdConfirmShow: false,
      }
    }
  },
  created() {
    this.getUserData()
    this.getPwdData()
  },
  methods: {
    exchangePhone() {
      this.$refs.verifyPhoneRef.open(this.userData)
    },
    handleVerify(e) {
      this.$refs.modifyPhoneRef.open({token: e.token})
    },
    getUserData() {
      userProfileApi({type: 'QUERY'}).then(res => {
        console.log('个人信息', res)
        this.userData = res.data
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getPwdData() {
      changePwdApi({type: 0}).then(res => {
        console.log('密码信息', res)
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    sendSms() {

    },
    handleSettingPwd() {
      if(!this.isModifybyPwd) return this.$tips({message: '暂不支持该功能', type: 'warning'})
      // if(!this.form.oldPwdOrSms.trim()) return this.$tips({message: this.isModifybyPwd ? '请输入原密码':'请输入验证码', type: 'warning'})
      if(!this.form.newPwd.trim()) return this.$tips({message: '请输入新密码', type: 'warning'})
      if(!this.form.newPwdConfirm.trim()) return this.$tips({message: '请确认新密码', type: 'warning'})
      if(this.form.newPwd != this.form.newPwdConfirm) return this.$tips({message: '两次新密码输入不一致', type: 'warning'})
      setPwdApi({ 
        'pwd': pwdEncrypt(this.form.newPwd.trim())
      }).then(res => {
        console.log(res)
        this.$tips({message: res.msg, type: 'success'})
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  align-items: center;
  .tab {
    font-size: 16px;
    padding-bottom: 16px;
    text-align: center;
    margin-right: 50px;
    cursor: pointer;
  }
  .active {
    position: relative;
    color: var(--theme-color);
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 4px;
      height: 2px;
      width: 80px;
      background-color: var(--theme-color);
    }
  }
}
.border-bottom {
  border-bottom: 1px solid #EEEEEE;
}
.btn-change {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  padding: 0 30px;
  margin-left: 100px;
  cursor: pointer;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
}
.required {
  position: relative;
  &::before {
    content: '*';
    position: absolute;
    top: 2px;
    left: -6px;
    color: var(--theme-color-red);
  }
}
.btn-sms {
  width: 102px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  margin-left: 8px;
  font-size: 14px;
  cursor: pointer;
}

// 密码设置
.setting-wrap {
  width: 230px;
  margin-top: 24px;
  .icon-eyes {
    line-height: 40px;
    cursor: pointer;
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  .btn-cancel, .btn-confirm {
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 12px;
    cursor: pointer;
  }
}
</style>