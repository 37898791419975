<template>
  <Popup ref="popupRef" title="验证旧安全手机号" width="370px" @confirm="confirm" @close="resetData">
    <div class="label">旧安全手机号</div>
    <el-input placeholder="请输入新的手机号" :value="oldPhone" disabled></el-input>
    <div class="label mt24">验证码</div>
    <div class="flex">
      <div class="flex-1">
        <el-input type="number" placeholder="请输入验证码" v-model="form.sms"></el-input>
      </div>
      <div :class="['btn-sms', isSendSms ? 'btn-border':'btn-fill-theme']" @click="sendSms">
        {{isSendSms ? `剩余${countdown}` : '获取验证码'}}
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { changePhoneApi } from '@/api/user.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      countdown: 60,
      isSendSms: false,
      form: {
        sms: ''
      },
      token: '',
      oldPhone: ''
    }
  },
  methods: {
    open(info) {
      this.oldPhone = info.phone || ''
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.form.sms = ''
    },
    sendSms() {
      if(this.isSendSms) return
      changePhoneApi({
        init: true,
      }).then(res => {
        console.log('发送验证码', res)
        this.token = res.data.token
        this.isSendSms = true
        const timer = setInterval(() => {
          this.countdown--
          if(this.countdown == 0) {
            this.isSendSms = false
            this.countdown = 60
            clearInterval(timer)
          }
        }, 1000)
      }).catch(err => {
        this.$tips({  message: err, type: 'error' })
      })
    },
    confirm() {
      if(!this.form.sms.toString().trim()) return this.$tips({  message: '请输入验证码', type: 'warning' })
      changePhoneApi({
        code: this.form.sms.toString(),
        token: this.token
      }).then(res => {
        console.log('验证旧手机', res)
        this.$emit('verify', {token: this.token})
        this.close()
      }).catch(err => {
        this.$tips({  message: err, type: 'error' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
}
.btn-sms {
  width: 102px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  margin-left: 8px;
  cursor: pointer;
}
</style>